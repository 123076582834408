import { ReSelect } from 'components/ui/Input';
import { useTranslations } from 'hooks';

const MonthInput = ({ required = false, defaultValue, name = 'days' }) => {
	const { translate } = useTranslations();

	const months = [
		{ value: 0, label: translate('january') },
		{ value: 1, label: translate('february') },
		{ value: 2, label: translate('march') },
		{ value: 3, label: translate('april') },
		{ value: 4, label: translate('may') },
		{ value: 5, label: translate('june') },
		{ value: 6, label: translate('july') },
		{ value: 7, label: translate('august') },
		{ value: 8, label: translate('september') },
		{ value: 9, label: translate('october') },
		{ value: 10, label: translate('november') },
		{ value: 11, label: translate('december') },
	];

	return (
		<div>
			<ReSelect name={name} required={required} defaultValue={defaultValue} options={months} />
		</div>
	);
};
export default MonthInput;
