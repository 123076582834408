import { FormEntry, InternalFormDivider } from 'components/form';
import { Input } from 'components/ui/Input';
import { useTranslations } from 'hooks';
import DayOfMonthInput from '../../components/DayOfMonthInput';
import DayOfWeeksInput from '../../components/DaysOfWeekInput';
import MonthInput from '../../components/MonthInput';
const YearlyForm = ({ isView, data }) => {
	const { translate } = useTranslations();
	return (
		<>
			<InternalFormDivider>{translate('yearlyRecurrence')}</InternalFormDivider>
			<FormEntry label='interval'>
				<Input
					required
					isView={isView}
					type='number'
					min={0}
					placeholder={'interval'}
					name='recurrenceYearlyModel.interval'
					defaultValue={data?.recurrenceYearlyModel?.interval}
				/>
			</FormEntry>
			<FormEntry label='dayOfWeek'>
				<DayOfWeeksInput
					required
					isMultiple={false}
					defaultValue={data?.recurrenceYearlyModel?.dayOfWeek}
					name='recurrenceYearlyModel.dayOfWeek'
				/>
			</FormEntry>
			<FormEntry label='dayOfMonth'>
				<DayOfMonthInput
					required
					defaultValue={data?.recurrenceYearlyModel?.dayOfMonth}
					name='recurrenceYearlyModel.dayOfMonth'
				/>
			</FormEntry>
			<FormEntry label='monthlyOccurrence'>
				<Input
					required
					isView={isView}
					type='number'
					min={0}
					placeholder={'monthlyOccurrence'}
					name='recurrenceYearlyModel.monthlyOccurrence'
					defaultValue={data?.recurrenceYearlyModel?.monthlyOccurrence}
				/>
			</FormEntry>
			<FormEntry label='month'>
				<MonthInput
					required
					defaultValue={data?.recurrenceYearlyModel?.month}
					name='recurrenceYearlyModel.month'
				/>
			</FormEntry>
		</>
	);
};
export default YearlyForm;
