import { FormEntry, InternalFormDivider } from 'components/form';
import { CheckBox, Input } from 'components/ui/Input';
import { useTranslations } from 'hooks';

const DailyForm = ({ isView, data }) => {
	const { translate } = useTranslations();
	return (
		<>
			<InternalFormDivider>{translate('dailyRecurrence')}</InternalFormDivider>
			<FormEntry label='interval'>
				<Input
					required
					isView={isView}
					type='number'
					min={0}
					placeholder={'interval'}
					name='recurrenceDailyModel.interval'
					defaultValue={data?.recurrenceDailyModel?.interval}
				/>
			</FormEntry>
			<FormEntry>
				<CheckBox
					isView={isView}
					name='recurrenceDailyModel.everyWeekday'
					label='everyWeekday'
					selected={data?.recurrenceDailyModel?.everyWeekday}
				/>
			</FormEntry>
		</>
	);
};
export default DailyForm;
