import { Form, FormEntry } from 'components/form';
import { CheckBox, DateTimeInput, Input, ReSelect } from 'components/ui/Input';
import { Loading } from 'components/ui/Interactive';
import { useAside, useCrud, useForm, useTranslations } from 'hooks';
import TaskTemplateService from 'modules/tasks/pages/Manage/pages/TaskTemplates/pages/TaskTemplates/services';
import TaskGroupService from 'modules/tasks/pages/Manage/services';
import { runAction } from 'modules/utils';
import { Suspense, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import DailyForm from './RecurrenceForms/Daily.form';
import MonthlyForm from './RecurrenceForms/Monthly.form';
import WeeklyForm from './RecurrenceForms/Weekly.form';
import YearlyForm from './RecurrenceForms/Yearly.form';

const EventForm = forwardRef(({ isView = false, ...props }, ref) => {
	const { translate } = useTranslations();
	const { asideBuilder } = useAside();
	const { getOne } = useCrud(props.service);
	const { getData } = useForm();

	const componentMap = {
		Daily: DailyForm,
		Weekly: WeeklyForm,
		Monthly: MonthlyForm,
		Yearly: YearlyForm,
	};

	const taskGroupService = new TaskGroupService();
	const taskTemplateService = new TaskTemplateService();

	const [data, setData] = useState([]);
	const myForm = useRef(null);
	const [loading, setLoading] = useState(true);
	const [recurrenceTypeOptions, setRecurrenceTypeOptions] = useState([]);
	const [categoryTypeOptions, setCategoryTypeOptions] = useState([]);
	const [taskTemplateOptions, setTaskTemplateOptions] = useState([]);
	const [activeRecurrence, setActiveRecurrence] = useState(null);

	const fetchTaskTemplates = (taskGroupId = -1) => {
		taskTemplateService.setParent(taskGroupId);
		taskTemplateService.getOptionsList().then((res) => {
			setTaskTemplateOptions(res.data || []);
		});
	};

	const Component = activeRecurrence ? componentMap[activeRecurrence] : null;

	const fetchRecurrenceTypes = async () => {
		await runAction('tenants', 'getEnum', 'RecurrenceType')
			.then((enumValues) => {
				const l_recurrenceTypes = [];
				Object.keys(enumValues).forEach((key) => {
					l_recurrenceTypes.push({
						label: translate(key, true),
						value: enumValues[key],
					});
				});

				setRecurrenceTypeOptions(l_recurrenceTypes);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const getCategoryTypes = async () => {
		await runAction('tenants', 'getEnum', 'ScheduleCategory')
			.then((enumValues) => {
				const l_catTypes = [];
				Object.keys(enumValues).forEach((key) => {
					l_catTypes.push({
						label: translate(key, true),
						value: enumValues[key],
					});
				});

				setCategoryTypeOptions(l_catTypes);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const initiateData = () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				setData(res);
				fetchTaskTemplates(res?.categoryTaskModel.taskGroupId);
				setLoading(false);
			});
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchRecurrenceTypes();
		getCategoryTypes();
		initiateData();
	}, []);

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	const closeAside = () => {
		asideBuilder.reset();
		asideBuilder.setOpen(false);
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(myForm.current),
		clear: () => resetData(),
	}));

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<div className='w-full h-100 pb-10 overflow-y-visible'>
				<Form ref={myForm}>
					<input type='hidden' name='id' value={data?.id || false} />

					<FormEntry label='name'>
						<Input
							isView={isView}
							type='text'
							placeholder={'name'}
							required={true}
							name='name'
							defaultValue={data?.name}
						/>
					</FormEntry>

					<FormEntry label='categoryType'>
						<ReSelect
							required
							isView={isView}
							name='categoryType'
							options={categoryTypeOptions}
							defaultValue={data?.categoryType}
						/>
					</FormEntry>
					<FormEntry label='startDateTime'>
						<DateTimeInput
							isView={isView}
							type='text'
							placeholder={'startDateTime'}
							required={true}
							name='startDateTime'
							defaultValue={data?.startDateTime || props?.data?.startDateTime || new Date()}
						/>
					</FormEntry>
					<FormEntry label='endAfterOccurrences'>
						<Input
							isView={isView}
							type='number'
							min={0}
							placeholder={'endAfterOccurrences'}
							name='endAfterOccurrences'
							defaultValue={data?.endAfterOccurrences}
						/>
					</FormEntry>
					<FormEntry label='taskGroup'>
						<ReSelect
							isView={isView}
							required
							name='taskGroup'
							service={taskGroupService}
							onChange={(value) => fetchTaskTemplates(value)}
							defaultValue={data?.categoryTaskModel?.taskGroupId}
						/>
					</FormEntry>
					<FormEntry label='taskTemplate'>
						<ReSelect
							required
							isView={isView}
							name='categoryTaskModel.taskTemplateId'
							options={taskTemplateOptions}
							defaultValue={data?.categoryTaskModel?.taskTemplateId}
						/>
					</FormEntry>
					<FormEntry label={'enabled'}>
						<CheckBox isView={isView} name='enabled' label='enabled' selected={data?.enabled} />
					</FormEntry>
					<FormEntry label='recurrenceType'>
						<ReSelect
							required
							isView={isView}
							name='recurrenceType'
							options={recurrenceTypeOptions}
							defaultValue={data?.recurrenceType}
							onChange={(value) => {
								if (!value) {
									setActiveRecurrence(null);
									return;
								}
								const label = recurrenceTypeOptions.find((x) => x.value === value)?.label;
								setActiveRecurrence(label);
							}}
						/>
					</FormEntry>
					{Component && <Component isView={isView} data={data} />}
				</Form>
			</div>
		</Suspense>
	);
});

export default EventForm;
