import { ReSelect } from 'components/ui/Input';

const DayOfMonthInput = ({ required = false, defaultValue, name = 'days' }) => {
	let days = [];
	for (let i = 0; i < 31; i++) {
		if (i < 9) days.push({ value: i, label: '0' + (i + 1) });
		else days.push({ value: i, label: i + 1 });
	}

	return (
		<div>
			<ReSelect name={name} required={required} defaultValue={defaultValue} options={days} />
		</div>
	);
};
export default DayOfMonthInput;
