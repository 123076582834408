import { FormEntry, InternalFormDivider } from 'components/form';
import { Input } from 'components/ui/Input';
import { useTranslations } from 'hooks';
import DayOfWeeksInput from '../../components/DaysOfWeekInput';
const WeeklyForm = ({ isView, data }) => {
	const { translate } = useTranslations();
	return (
		<>
			<InternalFormDivider>{translate('weeklyRecurrence')}</InternalFormDivider>
			<FormEntry label='interval'>
				<Input
					required
					isView={isView}
					type='number'
					min={0}
					placeholder={'interval'}
					name='recurrenceWeeklyModel.interval'
					defaultValue={data?.recurrenceWeeklyModel?.interval}
				/>
			</FormEntry>
			<FormEntry label='daysOfWeek'>
				<DayOfWeeksInput
					required
					defaultValue={data?.recurrenceWeeklyModel?.daysOfWeek}
					name='recurrenceWeeklyModel.daysOfWeek'
				/>
			</FormEntry>
		</>
	);
};
export default WeeklyForm;
